import { useAuth } from "@localkitchens/passwordless-auth";
import { useState } from "react";
import { BoardOrientation, Order } from "../../types";
import useInterval from "../../utils/useInterval";

import { chunkify } from "./utils/chunkify";
import StatusBoardFooter from "./components/Footer";
import { useStoreOrders } from "./hooks/useStoreOrders";
import useBoardOrientation from "../../utils/useBoardOrientation";
import OrderCard from "./components/OrderCard";
import { AnimatePresence } from "framer-motion";
import EmptyBoard from "./components/EmptyBoard";
import StatusBoardOrders from "./components/StatusBoardOrders";
import StatusBoardPage from "./components/StatusBoardPage";

const PAGE_SPEED_IN_SECONDS = 10;
const ORDER_PER_PAGE_HORIZONTAL = 16;
const ORDER_PER_PAGE_PORTRAIT = 18;

type StatusBoardProps = {
  currentLocation: string;
};

export const StatusBoard = ({ currentLocation }: StatusBoardProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const orientation = useBoardOrientation();
  const { orders } = useStoreOrders(currentLocation);
  const { logout } = useAuth();

  const isPortrait = orientation === BoardOrientation.Portrait;
  const ordersPerPage = isPortrait
    ? ORDER_PER_PAGE_PORTRAIT
    : ORDER_PER_PAGE_HORIZONTAL;

  const allOrdersPaginated = chunkify(orders, ordersPerPage);
  const numPages = allOrdersPaginated.length;

  const currentPageNum = currentPage % numPages || 0;
  const currentPageOrders = allOrdersPaginated[currentPageNum] || [];

  const isEmpty = orders.length === 0;

  useInterval(() => {
    if (isEmpty) {
      setCurrentPage(0);
    } else {
      setCurrentPage((prev) => (prev + 1) % numPages);
    }
  }, PAGE_SPEED_IN_SECONDS * 1000);

  if (isEmpty) return <EmptyBoard isPortrait={isPortrait} />;

  return (
    <StatusBoardPage isPortrait={isPortrait}>
      <AnimatePresence>
        <StatusBoardOrders
          isPortrait={isPortrait}
          numPages={numPages}
          currentPageNum={currentPageNum}
        >
          {currentPageOrders.map((order: Order) => (
            <OrderCard key={order.id} order={order} />
          ))}
        </StatusBoardOrders>
      </AnimatePresence>
      <StatusBoardFooter
        handleLogout={() => {
          logout({
            returnTo: window.location.origin,
          });
        }}
      />
    </StatusBoardPage>
  );
};
