import React from "react";
import { twMerge } from "tailwind-merge";

export type ComponentType =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "div"
  | "span"
  | "p"
  | "blockquote"
  | "small";

type HeaderTextProps = React.HTMLProps<HTMLHeadingElement> &
  TypographyBaseProps;

export const typographyStyles = {
  heading3: "font-semibold text-[2rem] leading-[3.4rem]",
  body1: "font-normal text-[2rem] leading-[3.4rem]",
} as const;

type Variant = keyof typeof typographyStyles;

const variantComponents: Record<Variant, ComponentType> = {
  heading3: "h2",
  body1: "div",
};

export type TypographyBaseProps = {
  variant: Variant;
  className?: string;
  component?: ComponentType;
  children?: React.ReactNode;
  truncate?: boolean;
};

export const Typography = ({
  component,
  variant,
  className,
  truncate,
  ...rest
}: HeaderTextProps) => {
  const Component = component || variantComponents[variant];
  if (truncate) {
    return React.createElement(
      "div",
      {
        className: twMerge(typographyStyles[variant], className),
        ...rest,
      },
      React.createElement(
        Component,
        {
          className: "truncate",
          ...rest,
        },
        rest?.children,
      ),
    );
  }

  return React.createElement(
    Component,
    {
      className: twMerge(typographyStyles[variant], className),
      ...rest,
    },
    rest?.children,
  );
};
