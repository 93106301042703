import classNames from "classnames";
import PageIndicator from "./PageIndicator";

const StatusBoardOrders = ({
  numPages,
  currentPageNum,
  children,
  isPortrait,
}: {
  numPages: number;
  currentPageNum: number;
  children?: React.ReactNode;
  isPortrait: boolean;
}) => {
  return (
    <>
      <div className="flex flex-col grow">
        <PageIndicator currentPage={currentPageNum + 1} totalPages={numPages} />

        <div
          className={classNames(
            "gap-8 p-8 pt-0 grow",
            isPortrait
              ? "grid grid-cols-3 grid-rows-6"
              : "grid grid-cols-4 grid-rows-4",
          )}
        >
          {children}
        </div>
      </div>
      <Banner isPortrait={isPortrait} />
    </>
  );
};

const Banner = ({ isPortrait }: { isPortrait: boolean }) => {
  const url = isPortrait
    ? "/images/horizontal_banner.png"
    : "/images/vertical_banner.png";
  const className = isPortrait ? "w-full h-auto" : "h-full w-auto";
  return (
    <img
      src={url}
      alt="Upsell QR code to promote downloading the app."
      className={className}
    />
  );
};

export default StatusBoardOrders;
