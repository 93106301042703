const EmptyBoard = ({ isPortrait }: { isPortrait: boolean }) => {
  const url = isPortrait
    ? "/images/empty_vertical.png"
    : "/images/empty_horizontal.png";
  const className = isPortrait ? "w-full h-auto" : "w-full h-auto";
  return (
    <div className="w-screen h-screen">
      <img
        src={url}
        alt="Upsell QR code to promote downloading the app."
        className={className}
      />
    </div>
  );
};

export default EmptyBoard;
